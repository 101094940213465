'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterMain = /** @class */function () {
    function FooterMain() {
        this.footerDom = document.getElementById('kingshiper-footer');
        this.getCookieDom = document.getElementById('kingshiper-get-cookies');
        this.init();
    }
    FooterMain.prototype.init = function () {
        this.serviceEvent();
        this.gotCookieHandle();
    };
    FooterMain.prototype.serviceEvent = function () {
        var toTopBtn = _tool2.default.$_select(this.footerDom, '.to-top-btn');
        toTopBtn.addEventListener('click', function () {
            window.scrollTo({
                left: 0,
                top: 0,
                behavior: "smooth"
            });
        });
    };
    // 允许获取cookie，点击按钮Got it
    FooterMain.prototype.gotCookieHandle = function () {
        var _this = this;
        var gotItBtn = _tool2.default.$_select(this.getCookieDom, '.got-it-btn');
        // 查看是否存在允许获取cookie
        var cookieValue = _tool2.default.getCookie('cookie-allow');
        if (cookieValue == '1') this.getCookieDom.style.display = 'none';
        gotItBtn.addEventListener('click', function () {
            _this.getCookieDom.style.display = 'none';
            var expirationDate = new Date();
            expirationDate.setDate(expirationDate.getFullYear() + 1);
            document.cookie = "cookie-allow=1; expires=".concat(expirationDate.toUTCString());
        });
    };
    return FooterMain;
}();
exports.default = FooterMain;