"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
// 静态配置
var Config = /** @class */function () {
    function Config() {}
    Config.WC_HOST = "http://abroadwc.onlinedo.cn"; // 测试
    Config.WM_HOST = "http://tp6.callmysoft.ceshi.lasdth.com/";
    //验签key
    Config.Wc_key = "MzqTiAXD0aVeEmi7D7N5ckBvDS4U";
    // 登录接口
    Config.WM_GETEMAILCODE = '/index/UnifiedInterface/get_email_code'; //获取邮箱验证码
    Config.WM_EMAIL_LOGIN = '/index/UnifiedInterface/email_login'; //邮箱号登录
    // 支付
    Config.WM_PURCHASE = '/paypalLink'; //paypal支付
    // static WM_PURCHASE = '/generateLink';    //paypal支付
    Config.WC_RECOMMEND = '/productRecommend'; // 支付成功后推荐产品                                                     
    // 公共顶部
    Config.WM_LOGINOUT = '/logout'; //退出登录
    // 个人中心接口
    Config.WM_USEREQUEST = "/index/CommunityApi";
    Config.WM_UPDATEHEADIMG = '/index/UnifiedInterface/set_user_info'; //修改头像
    Config.WM_BINDACCOUT = '/index/UnifiedInterface/binding_account'; //绑定账号
    Config.WM_BINDSURE = '/index/UnifiedInterface/vecode_confirm'; //确定绑定，下一步
    Config.WM_DELETEEMAIL = '/index/UnifiedInterface/delete_emali_code'; //邮箱号注销
    Config.WM_DELETEEMAILCONFIRM = '/index/UnifiedInterface/delete_email_confirm'; //邮箱号注销确认
    Config.WM_DOWN_EXCHANGECODE = '/index/UnifiedInterface/get_order_discount_code'; //下载兑换码
    Config.WM_ORDER = '/myOrder'; // My Order订单列表
    Config.WM_EDIT_PROFILE = '/editProfile'; // Profile修改头像、昵称
    Config.WM_FORGOT_PASSWORD = '/forgotPassword'; // Security发送验证码
    Config.WM_VERIFYEMAIL = '/verifyEmail'; // Security校验验证码
    Config.WM_RESET_PASSWORD = '/reset'; // Security重置密码
    // 搜索结果页
    Config.WM_GETARTICLELIST = '/articleList'; //获取教程列表
    Config.WM_GETSEARCHRESULT = '/searchResultApi'; //获取搜索结果页列表
    //提示内容
    Config.Wm_tips_email_empty = "Please enter your e-mail address";
    Config.Wm_tips_email_error = "E-mail address is wrong";
    Config.Wm_tips_password_empty = "Please enter your password";
    Config.Wm_tips_password_error = "Password must contain 6-12 digits or letters";
    Config.Wm_tips_password_match = "The passwords do not match.";
    Config.Wm_tips_code_empty = "验证码为空";
    Config.Wm_tips_getCode = "请先获取验证码";
    Config.Wm_tips_fullCode = "Please enter the four digit verification code";
    // wc接口
    Config.WC_REGISTER = '/register'; // 用户注册
    Config.WC_LOGIN = '/login'; // 用户登录   
    Config.WC_FORGOTPASSWORD = '/forgotPassword'; // 忘记密码
    Config.WC_VERIFYEMAIL = '/verifyEmail'; // 邮件确认
    Config.WC_RESET = '/reset'; // 重置密码     
    // 联系我们
    Config.WM_CONTACT = '/contact'; // contact提交表单
    return Config;
}();
exports.default = Config;