'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterMain = /** @class */function () {
    function FooterMain() {
        this.footerDom = document.getElementById('kingshiper-footer');
        this.init();
    }
    FooterMain.prototype.init = function () {
        this.serviceEvent();
    };
    FooterMain.prototype.serviceEvent = function () {
        var toTopBtn = _tool2.default.$_select(this.footerDom, '.to-top-btn');
        toTopBtn.addEventListener('click', function () {
            window.scrollTo({
                left: 0,
                top: 0,
                behavior: "smooth"
            });
        });
    };
    return FooterMain;
}();
exports.default = FooterMain;